.main_Td {
  padding: 0;
}
:focus-visible {
  border-color: #aaa !important;
  outline: #aaa !important;
}

.inputWapper {
  display: flex;
  align-items: center;
  border: 1px black solid;
  position: relative;
  padding: 0 !important;
  margin: 0 !important;
  height: auto !important;
}

.inputWapper .input {
  /* border: none; */
  outline: none;
  width: 86%;
}

@media (max-width: 808) {
  .inputWrapper {
    font-size: 12px;
  }
}

.iconWapper img {
  height: 15px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  margin-top: -8px;
}

.dataTables_wrapper .dataTables_paginate {
  display: flex;
}

.inputImgWapper {
  position: absolute;
  right: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  border-left: 1px black solid;
}

.inputImgWapper img {
  width: 100%;
  height: 100%;
}

.my-custom-scrollbar {
  position: relative;
  height: 550px;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}
