.inputWrapper{
    display: flex;
    align-items: center;
}

.disableFld {
    pointer-events: none !important;
    background: #b6b6b670 !important;
    user-select: none !important;
    opacity: 0.7;
}

@media (max-width:470px){
    .inputWrapper{
        flex-direction: column;
    }
}