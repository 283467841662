.bgImg{
    width: 100%;
    height: 100vh !important;
    background-image: url("/assets/backgroundImg.jpg");
    background-size: cover;
    background-position:center;
    justify-content: center;
    align-items: center;
    display: flex;
}

.wrapper img {
    display: block;
    margin: auto;
    margin-top: 17px;    
}
.wrapper span {
    display: block;
    display: flex;
    margin-top: 10px;
    justify-content: center;
}
  
.input-wrapper {
    width: 100%;
    display: flex;
    border-radius: 7px;
    align-items: center;
    border: 1px rgba(230, 227, 227, 0.575) solid;
    /* outline: none !important; */
    padding-left: 8px;
    margin-bottom:15px;
}

.logoText{
    font-family: "Poppins" ;
    font-size:12px;
  }
  
.forgotbtn{
    cursor: pointer;
}

.icon {
    width: 8%;
}

.input-field {
    /* flex: 1; */
    padding: 10px 5px 10px 5px;
    border: none;
    width: 90%;
    border-color: grey;
    margin-bottom: 10;
    outline: none;
}
.input-wrapper:focus-within  {
    border: 1px rgba(128, 128, 128, 0.575) solid !important;
    /* background-color: aquamarine; */
}