.dot{
    background: green;
    width: 10px;
    border-radius: 50%;
    height: 10px;
    display: block;
    margin-top: 10px;
}
.dot2{
    background: rgb(221, 71, 71);
    width: 10px;
    border-radius: 50%;
    height: 10px;
    display: block;
    margin-top: 10px;
}
.txt{
    margin-top: -10px;
}
.main{
    width: auto;
    display: flex;
    align-items: center;
    gap: 5px;
}
.img_Border{
    border-radius: 13px !important;
    width: 20vh !important;
    height: 19vh !important ;
    margin:0 !important;

}