.flavors{
    background-color: rgba(236, 236, 236, 0.705);
    width: auto;
    border: 1px solid darkgray;
    border-radius: 100px;
    padding: 6px 10px;
    text-align: center;
    align-items: center;
    transition: 0.2s ease-out;
    cursor: pointer;
}
.flavors:hover {
    transform: scale(0.9);
}