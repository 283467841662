
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
   }
   
   .container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 20px;
    user-select: none;
   }
   

   .checkmark {
    position: relative;
    top: 3px;
    /* left: 50px; */
    height: 23px;
    width: 23px;
    background: #6260602e;
    border-radius: 7px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.30), 0px 1px 1px rgba(0,5);
   }
   

   .container input:checked ~ .checkmark {
    background-image: linear-gradient(#5cb85c,#5cb85c)     }

   
   .checkmark:after {
    content: "";
    position: absolute;
    display: none;
   }
   
   .container input:checked ~ .checkmark:after {
    display: block;
   }
   

   .container .checkmark:after {
    left: 0.45em;
    top: 0.20em;
    width: 0.24em;
    height: 0.6em;
    border: solid white;
    border-width: 0 0.15em 0.15em 0;
    transform: rotate(45deg);
   }