.inputWrapper{
    display: flex;
    align-items: center;
}



@media (max-width:520px){
    .inputWrapper{
        flex-direction: column;
    }
}